/* eslint-env browser */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InfoPage from '../../components/InfoPage';
import ContentBox from '../../components/sub-components/contentBox';
import WorkText from '../../components/sub-components/workText';

const work = {
  title: 'Petite Cascade',
  date: 2019,
  price: 90,
  available: true,
  url:'Petite-Cascade',
  medium: 'Acrylic on Canvas',
  width: 6,
  height: 12,
}
class Petite extends Component {

  render() {
    return (
      <InfoPage
        title="Petite Cascade by Jennifer Mone Hill"
        description="Petite Cascade by Jennifer Mone Hill Artist."
        keywords={[
          'Jennifer Moné Hill Artist',
          'Jen Hill',
          'Jennifer Moné Hill',
          'Jen Hill Artist',
          'Jennifer Mone Hill',
        ]}
        imageLink="/works"
        imgSrc="Petite-Cascade-by-Jennifer-Mone-Hill.jpg"
        imgSrc2x="Petite-Cascade-by-Jennifer-Mone-Hill.jpg"
        alt="Petite Cascade by Jennifer Moné Hill"
        imageMarginTop={15}
        customImageWidth={30}
      >
        <ContentBox>
          <WorkText work={work} />
        </ContentBox>
      </InfoPage>
    );
  }
}

Petite.propTypes = {
  location: PropTypes.object,
};

export default Petite;
